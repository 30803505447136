import React from "react"
import Aklecturenine from "../../static/akthyw2021/aklecturenine.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklecturenine}>pdf</a>
  </>
)

export default AlgebraicKtheory

