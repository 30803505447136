import React from "react"
import Complete from "../../static/akthyw2021/complete.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Complete}>pdf</a>
  </>
)

export default AlgebraicKtheory

