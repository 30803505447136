import React from "react"
import Aklectureseven from "../../static/akthyw2021/aklectureseven.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklectureseven}>pdf</a>
  </>
)

export default AlgebraicKtheory

