import React from "react"
import Aklecturefive from "../../static/akthyw2021/aklecturefive.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklecturefive}>pdf</a>
  </>
)

export default AlgebraicKtheory

