import React from "react"
import Aklecturethree from "../../static/akthyw2021/aklecturethree.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklecturethree}>pdf</a>
  </>
)

export default AlgebraicKtheory

