import React from "react"
import Aklecturetwo from "../../static/akthyw2021/aklecturetwo.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklecturetwo}>pdf</a>
  </>
)

export default AlgebraicKtheory

