import React from "react"
import Aklectureeight from "../../static/akthyw2021/aklectureeight.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklectureeight}>pdf</a>
  </>
)

export default AlgebraicKtheory

