import React from "react"
import Aklectureeleven from "../../static/akthyw2021/aklectureeleven.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklectureeleven}>pdf</a>
  </>
)

export default AlgebraicKtheory

