import React from "react"
import Aklecturethirteen from "../../static/akthyw2021/aklecturethirteen.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklecturethirteen}>pdf</a>
  </>
)

export default AlgebraicKtheory

