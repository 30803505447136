import React from "react"
import Aklecturetwelve from "../../static/akthyw2021/aklecturetwelve.pdf" // Tell webpack this JS file uses this pdf

const AlgebraicKtheory = () => (
  <>
    <a href={Aklecturetwelve}>pdf</a>
  </>
)

export default AlgebraicKtheory

